import { PopoversNames } from '@/layouts/Planos/Popovers/constants';
import { precosEPlanosButtonNames } from '@/constants/analytics';

export interface PlanoInfo {
  type: 'text' | 'sub' | 'desc';
  name: string;
  text?: string;
}

export interface PlanoFuncionalidade {
  name: string;
  info?: PlanoInfo;
  isNew?: boolean;
  small?: boolean;
  soon?: boolean;
  isPreLaunch?: boolean;
}

export interface Plano {
  nome: string;
  descricao: string;
  valor?: number;
  planoFull: string;
  funcionalidades: Array<string | PlanoFuncionalidade>;
  gaMetadata: { tryButtonName: string };
  promo?: number;
  promoDescription?: string;
  mostPopular?: boolean;
  preview?: boolean;
  aPartirDe?: boolean;
  isAnual?: boolean;
}

export const PlanoNames = {
  Agenda: 'Agenda',
  Gestao: 'Gestão',
  Vendas: 'Vendas',
  Redes: 'Redes e Franquias',
};

export const planos: Plano[] = [
  {
    nome: PlanoNames.Agenda,
    descricao: 'Cuide de seus pacientes e da sua agenda',
    valor: 139.9,
    planoFull: 'Plano Agenda',
    funcionalidades: [
      'Usuários ilimitados',
      {
        name: 'Confirmação de consulta',
        info: {
          type: 'sub',
          name: PopoversNames.confirm,
          text: '* Mensagens SMS e WhatsApp adquiridos à parte',
        },
      },
      {
        name: 'Aplicativo para pacientes (Meu Doutor)',
        info: {
          type: 'desc',
          name: PopoversNames.meuDoutor,
        },
        isNew: true,
      },
      'Prontuário digital e emissão de documentos',
      { name: 'Odontograma', isNew: true },
      'Campanhas de marketing *',
      { name: 'Orçamento Parcial', isNew: true },
      { name: 'Site do Dentista', isNew: true },
      { name: 'Agenda integrada à Alexa', isNew: true },
    ],
    gaMetadata: {
      tryButtonName: precosEPlanosButtonNames.testarGratisCardAgenda,
    },
  },
  {
    nome: PlanoNames.Gestao,
    descricao: 'Descomplique a gestão do seu consultório',
    valor: 214.9,
    planoFull: 'Plano Agenda +',
    funcionalidades: [
      'Fluxo de caixa e comissionamento',
      'Emissão de boletos bancários',
      'Maquininha de cartão integrada',
      'Ferramenta para ortodontista',
      'Indicadores e relatórios de performance',
      {
        name: 'Assinatura Eletrônica',
        info: {
          type: 'sub',
          name: PopoversNames.assinatura,
          text: '* Pacotes adquiridos à parte',
        },
        isNew: true,
      },
      { name: 'Agenda integrada à Alexa', isNew: true },
      { name: 'Automação para controle de taxas de maquininha', isNew: true },
    ],
    gaMetadata: {
      tryButtonName: precosEPlanosButtonNames.testarGratisCardGestao,
    },
  },
  {
    nome: PlanoNames.Vendas,
    descricao: 'Mais agilidade no processo de vendas',
    valor: 299.9,
    planoFull: 'Plano Gestão +',
    funcionalidades: [
      'Ferramenta de vendas',
      'Metas de vendas',
      'Funil de orçamento',
      {
        name: 'Confirmar e cancelar consultas gratuitamente pelo App para pacientes (Meu Doutor)',
        isNew: true,
      },
      'Funil de agendamento',
      {
        name: 'Integração com o WhatsApp Web',
        info: {
          type: 'desc',
          name: PopoversNames.automatizacaoWhatsapp,
        },
      },
      { name: 'Modelos de contratos', isNew: true },
      { name: 'Agenda integrada à Alexa', isNew: true },
      {
        name: 'Assinatura Eletrônica em contratos',
        info: {
          type: 'sub',
          name: PopoversNames.assinatura,
          text: '* Pacotes adquiridos à parte',
        },
        isNew: true,
      },
    ],
    mostPopular: true,
    gaMetadata: {
      tryButtonName: precosEPlanosButtonNames.testarGratisCardVendas,
    },
  },
  {
    nome: PlanoNames.Redes,
    descricao: 'Solução única para Redes e Franquias Odontológicas',
    planoFull: 'Plano Vendas +',
    funcionalidades: [
      {
        name: 'Painel analítico de inteligência para Redes',
        isNew: true,
      },
      {
        name: 'Mais de 30 indicadores com dados centralizados',
        isNew: true,
      },
      {
        name: 'Relatórios integrados por áreas, processos e profissionais',
        isNew: true,
      },
      {
        name: 'Acesso Multiclínicas',
        isNew: true,
      },
      {
        name: 'Ranking de performance das unidades',
        isNew: true,
      },
      {
        name: 'Compartilhamento de dados simplificado',
        isNew: true,
      },
      {
        name: '100% integrado ao Simples Dental',
        isNew: true,
      },
    ],
    mostPopular: false,
    preview: true,
    gaMetadata: {
      tryButtonName: precosEPlanosButtonNames.saibaMaisRedes,
    },
  },
];

export const planosAnuais: Plano[] = planos.map(plano => {
  let valor;
  let promo;
  let tryButtonName = precosEPlanosButtonNames.saibaMaisRedes;

  switch (plano.nome) {
    case PlanoNames.Agenda:
      valor = 1678.8;
      promo = 1538.9;
      tryButtonName = precosEPlanosButtonNames.testarGratisCardAgendaAnual;
      break;
    case PlanoNames.Gestao:
      valor = 2578.8;
      promo = 2363.9;
      tryButtonName = precosEPlanosButtonNames.testarGratisCardGestaoAnual;
      break;
    case PlanoNames.Vendas:
      valor = 3598.8;
      promo = 3298.9;
      tryButtonName = precosEPlanosButtonNames.testarGratisCardVendasAnual;
      break;
  }

  return {
    ...plano,
    valor,
    promo,
    isAnual: true,
    promoDescription: undefined,
    gaMetadata: {
      tryButtonName,
    },
  };
});
