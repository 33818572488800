import React from 'react';
import Container from 'react-bootstrap/Container';
import * as styles from './hero.module.scss';

export interface HeroProps {
  title: string;
  desc?: string;
}

const Hero = ({ title, desc }: HeroProps) => (
  <div className={styles.container}>
    <Container>
      <div className="d-flex flex-column justify-content-center align-items-center text-center">
        <p className={styles.subtitle}>NOSSOS PLANOS</p>
        <h1 className={styles.title}>{title}</h1>
        {desc && <p className={styles.desc}>{desc}</p>}
      </div>
    </Container>
  </div>
);

export default Hero;
