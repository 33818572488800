import React from 'react';

import Trigger from 'react-bootstrap/OverlayTrigger';

import Tag from '../Tag';
import { PopoversNames } from '../Popovers/constants';
import PopoverAssinatura from '../Popovers/PopoverAssinatura';
import PopoverSMS from '../Popovers/PopoverSMS';
import PopoverAutomatizacaoWhatsapp from '../Popovers/PopoverAutomatizacaoWhatsapp';
import PopoverMeuDoutor from '../Popovers/PopoverMeuDoutor';
import { CheckCircle } from '@/components/Icons';

import Info from '@/assets/svg/info.svg';
import * as styles from './card.module.scss';
import { PlanoInfo } from '@/constants/planos';

export interface FuncionalidadeProps {
  descricao: string;
  isNew?: boolean;
  info?: PlanoInfo;
  popular?: boolean;
  soon?: boolean;
  small?: boolean;
  isPreLaunch?: boolean;
}

const Funcionalidade = ({
  descricao,
  small,
  soon,
  info,
  popular,
  isNew,
  isPreLaunch,
}: FuncionalidadeProps) => {
  const popover = {
    [PopoversNames.confirm]: PopoverSMS,
    [PopoversNames.assinatura]: PopoverAssinatura,
    [PopoversNames.automatizacaoWhatsapp]: PopoverAutomatizacaoWhatsapp,
    [PopoversNames.meuDoutor]: PopoverMeuDoutor,
  };

  return (
    <li className={styles.funcionalidadeContainer}>
      {popular ? <CheckCircle color="white" /> : <CheckCircle />}
      <div>
        <div className={styles.descContainer}>
          {descricao}
          {info?.type === 'desc' && (
            <div className={styles.info}>
              <Trigger trigger="click" placement="auto" overlay={popover[info.name]} rootClose>
                <Info className={styles.infoIcon} />
              </Trigger>
            </div>
          )}
          {(isNew || isPreLaunch) && (
            <Tag
              text={isPreLaunch ? 'Pré-lançamento' : 'Novo'}
              className={isPreLaunch ? 'preLaunch' : 'small'}
            />
          )}
        </div>

        {info?.type === 'sub' && (
          <div className={styles.info}>
            <span>{info.text}</span>
            <Trigger trigger="click" placement="auto" overlay={popover[info.name]} rootClose>
              <Info className={styles.infoIconSmall} />
            </Trigger>
          </div>
        )}
      </div>
      {typeof small === 'string' && <span>{small}</span>}
      {soon && <span className={styles.soon}>(em breve)</span>}
    </li>
  );
};

export default Funcionalidade;
