import React from 'react';
import { Button } from '@/components';
import { StaticImage } from 'gatsby-plugin-image';

import * as styles from './faleconosco.module.scss';
import { ButtonClickMetadata, trackButtonClick } from '@/services/analytics';
import { navigate } from 'gatsby';
import { routes } from '@/constants/routes';

export interface FaleConoscoProps {
  gaMetadata: ButtonClickMetadata;
}

const FaleConosco = ({ gaMetadata: { event, buttonName } }: FaleConoscoProps) => {
  const handleFalarEspecialista = () => {
    navigate(routes.cadastroEspecialista);
    trackButtonClick({ event, buttonName });
  };

  return (
    <div className={styles.container}>
      <StaticImage
        src="../../../assets/images/especialista-da-simples-dental.jpeg"
        alt="tire suas dúvidas com a Simples Dental"
        className={styles.logo}
        placeholder="blurred"
        title="Especialista da Simples Dental com tablet nas mãos"
      />
      <div className={styles.infos}>
        <p className={styles.subtitle}>FALE COM A GENTE</p>
        <h2 className={styles.title}>
          Tem dúvidas de como a Simples Dental pode ajudar na sua clínica?
        </h2>
        <p className={styles.desc}>
          Fale com o nosso time de especialistas e tire todas as suas dúvidas.
        </p>
        <Button
          text="Falar com especialista"
          variant="primary"
          className={styles.button}
          onClick={handleFalarEspecialista}
        />
      </div>
    </div>
  );
};

export default FaleConosco;
