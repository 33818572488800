export interface PlanoDetalhes {
  descricao: string;
  agenda: boolean;
  gestao: boolean;
  vendas: boolean;
  isNew?: boolean;
  isInnovation?: boolean;
  isPreLaunch?: boolean;
  info?: string;
}

export const planosDetalhes: PlanoDetalhes[] = [
  {
    descricao: 'Agenda integrada à Alexa',
    agenda: true,
    gestao: true,
    vendas: true,
    isInnovation: true,
  },
  {
    descricao: 'Site do Dentista',
    agenda: true,
    gestao: true,
    vendas: true,
    isNew: true,
  },
  {
    descricao: 'Aplicativo para pacientes (Meu Doutor)',
    agenda: true,
    gestao: true,
    vendas: true,
    isNew: false,
  },
  {
    descricao: 'Odontograma',
    agenda: true,
    gestao: true,
    vendas: true,
    isNew: false,
  },
  {
    descricao: 'Orçamento Parcial',
    agenda: true,
    gestao: true,
    vendas: true,
    isNew: false,
  },
  {
    descricao: 'Usuários ilimitados',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Agenda no Aplicativo iOS/Android',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Confirmação e lembretes automáticos por WhatsApp (oficial) e SMS*',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Lembretes automáticos de aniversariantes, pós operatórios e retornos*',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Controle de retorno de pacientes',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Campanhas de Marketing via WhatsApp e SMS*',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Prontuário Digital - anamnese, orçamentos e documentos',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Armazenamento ilimitado de imagens e arquivos',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Anamneses personalizadas e com alertas',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Assinatura Eletrônica',
    agenda: false,
    gestao: true,
    vendas: true,
    isNew: true,
  },
  {
    descricao: 'Controle de Próteses',
    agenda: false,
    gestao: true,
    vendas: true,
    isNew: true,
  },
  {
    descricao: 'Emissão de boletos bancários',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Fluxo de caixa automático (receitas e despesas)',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Comissão de profissionais',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Maquininha de cartão integrada (Saúde Service)',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Controle de inadimplência e Notificação para paciente',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Controle de atividades da secretária',
    info: 'Confirmação de consultas, alertas de retorno, reagendamentos, débitos em atraso e mais!',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Indicadores e relatórios de performance',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao:
      'Relatório de ticket médio, lucratividade, taxa de comparecimento, aprovação de orçamentos e muito mais',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Ortodontia: ferramenta exclusiva',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Controle e otimização de vendas',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Controle de orçamentos não aprovados',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Integração com o WhatsApp Web - Converse com seus pacientes',
    agenda: false,
    gestao: false,
    vendas: true,
  },
  {
    descricao: 'Faceograma (HOF)',
    agenda: false,
    gestao: false,
    vendas: true,
  },
  {
    descricao: 'Confirmar e cancelar consultas gratuitamente pelo app para pacientes (Meu Doutor)',
    agenda: false,
    gestao: false,
    vendas: true,
  },
  {
    descricao: 'Modelos de contratos',
    agenda: false,
    gestao: false,
    vendas: true,
    isNew: true,
  },
  {
    descricao: 'Assinatura eletrônica em contratos',
    agenda: false,
    gestao: false,
    vendas: true,
    isNew: true,
  },
];
